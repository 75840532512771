const COOKIES = [
  '__april_project',
  '__april_project_situation',
  '__april_ref',
  'cmpid',
] as const

export type AprilCookie = (typeof COOKIES)[number]

export type CookieOption = {
  domain?: string
  maxAge?: number
}
export default defineNuxtPlugin(nuxtApp => {
  const cookieOptions: CookieOption = {
    domain: nuxtApp.$config.public.cookieDomain,
    maxAge: nuxtApp.$config.public.cookieTtl,
  }

  const cookies = Object.fromEntries(
    COOKIES.map((cookie) => [
      cookie,
      (options: CookieOption) =>
        useCookie(cookie, { ...cookieOptions, ...options }),
    ])
  )
  nuxtApp.provide('cookies', (cookie: AprilCookie, options: CookieOption = {})=>cookies[cookie](options))
})
