import { type ActionTree } from 'vuex'
import { type CaptureContext } from '@sentry/types'

import { type RootState } from './state'
import { type Offer } from '~/types/offers'
import { dateToYears } from '~/utils/date'
import { formatAdvantagesPushComponentPayload } from '~~/utils/prismic'
import { getOffersWithComparator } from '~~/utils/offers'

const actions: ActionTree<RootState, RootState> = {
  counterDown({ state, commit }) {
    const counter = state.counter
    commit('setCounter', counter - 1)
  },
  counterUp({ state, commit }) {
    const counter = state.counter
    commit('setCounter', counter + 1)
  },
  async fetchOffers(
    { rootState, commit },
    payload: { baseUrl: string; sentryContext?: CaptureContext }
  ) {
    const { dateNaissance, spouseBirthdate, projectId, comparatorOffer } =
      rootState.tgp
    const { baseUrl, sentryContext } = payload

    commit('setFetchingOffers', true)

    const { $sentryCaptureException, $sentryCaptureMessage } = useNuxtApp()

    const { data }: { data: Offer[] | any } = await fetch(
      `${baseUrl}/api/rate/${projectId}?mainInsuredAge=${
        dateNaissance && dateToYears(dateNaissance)
      }&spouseAge=${spouseBirthdate && dateToYears(spouseBirthdate)}`,
      { method: 'GET' },
    )
      .then((res) => res.json())
      .catch((e) => {
        if ($sentryCaptureException) {
          $sentryCaptureException(e, sentryContext)
        } else {
          // eslint-disable-next-line no-console
          console.error('Error while fetching rate:', e, sentryContext)
        }

        return []
      })

    if (Array.isArray(data)) {
      commit(
        'setOffers',
        comparatorOffer ? getOffersWithComparator(comparatorOffer, data) : data,
      )
    } else {
      const commonCtx: CaptureContext = {
        ...sentryContext,
        level: 'fatal',
        extra: {
          ...((sentryContext as any)?.extra || {}),
        },
      }

      if (data && data.error) {
        const ctx: CaptureContext = {
          ...commonCtx,
          extra: {
            ...commonCtx.extra,
            error: data.error,
          },
        }

        if ($sentryCaptureMessage) {
          $sentryCaptureMessage('Error while fetching rate, error', ctx)
        } else {
          // eslint-disable-next-line no-console
          console.error('Error while fetching rate:', ctx)
        }
      } else {
        const ctx: CaptureContext = {
          ...commonCtx,
          extra: {
            ...commonCtx.extra,
            data,
          },
        }

        if ($sentryCaptureMessage) {
          $sentryCaptureMessage('Error fetching rate, not an array', ctx)
        } else {
          // eslint-disable-next-line no-console
          console.error('Error while fetching rate', ctx)
        }
      }

      if (comparatorOffer) {
        // set array with the offer from comparator only
        commit('setOffers', [comparatorOffer])
      } else {
        // set empty array to display popin "no offer"
        commit('setOffers', [])
      }
    }

    commit('setFetchingOffers', false)
  },
  async nuxtServerInit({ commit, rootState, dispatch }, nuxtApp) {
    const baseUrl = nuxtApp.$config.public.baseURL
    // FETCH data from Ekomi
    const ekomi = await fetch(`${baseUrl}/api/ekomi`)
      .then((res) => res.json())
      .catch((error) => {
        /* eslint-disable-next-line no-console */
        console.error(error)
        // TODO: send error to Sentry (need to be implemented server side too)
        return { data: { error: error.toString() } }
      })

    if (ekomi.data.error) {
      /* eslint-disable-next-line no-console */
      console.error(`Error while fetching ekomi: ${ekomi.data.error}`)
      // TODO: send error to Sentry (need to be implemented server side too)
    } else {
      commit('setEkomi', ekomi.data)
    }

    // FETCH insurers list (for cancellation)
    const insurersList = await fetch(`${baseUrl}/api/insurances`)
      .then((res) => {
        if (!res.ok) {
          /* eslint-disable-next-line no-console */
          console.error(
            `Error response from API /api/insurances: ${res.status} - ${res.statusText}`,
          )
        }

        return res.json()
      })
      .catch((error) => {
        /* eslint-disable-next-line no-console */
        console.error(error)
        // TODO: send error to Sentry (need to be implemented server side too)
        return { data: { error: error.toString() } }
      })

    if (insurersList?.data && Object.hasOwn(insurersList.data, 'error')) {
      /* eslint-disable-next-line no-console */
      console.error(
        `Error while fetching insurers list: ${
          insurersList.data.error || '(empty)'
        }`,
      )
      // TODO: send error to Sentry (need to be implemented server side too)
    } else {
      const insurersListMapped = insurersList.data.map(
        (insurer: { id: string; code: string; label: string }) => ({
          id: insurer.id,
          value: insurer.code,
          label: insurer.label,
        }),
      )

      commit('setInsurers', insurersListMapped)
    }

    // FETCH data from Prismic: declare in 'documents' the list of (store key/prismic doc) mapping
    const documents = {
      adresse: 'tgps_adresse',
      detailAdresse: 'tgps_detail-adresse',
      cancellation: 'tgps_cancellation',
      coverLevels: 'tgps_cover_levels',
      children: 'tgps_children',
      dateEffet: 'tgps_date-effet',
      dateEffetDays: 'tgps_date-effet-day',
      contact: 'tgps_contact',
      dateNaissance: 'tgps_date-naissance',
      documents: 'tgps_documents-contractuels',
      family: 'tgps_family',
      iban: 'tgps_iban',
      madelin: 'tgps_madelin',
      offers: 'tgps_offers',
      panier: 'tgps_panier',
      projectSituation: 'tgps_project_situation',
      regime: 'tgps_regime',
      spouse: 'tgps_spouse',
      subscribeChildren: 'tgps_souscription-enfants',
      statutPro: 'tgps_professional_status',
      souscription: 'tgps_souscription',
      signature: 'tgps_signature',
      souscriptionSpouse: 'tgps_souscription_conjoint',
      prelevements: 'tgps_prelevements',
      adhesionValidee: 'tgps_adhesion_validee',
    }

    await Promise.all(
      Object.entries(documents).map(async ([key, value]) => {
        /* eslint-disable-next-line no-console */
        // console.debug(
        //   `Retrieve data for '${key}' from Prismic page '${value}'...`
        // )

        let retries = 5
        let success = false

        while (retries-- > 0 && !success) {
          success = await nuxtApp.$prismic
            .getSingle(value)
            .then((response: any) => {
              if (response) {
                const { data } = response
                commit('prismic/setStepData', { key, data })
                return true
              } else {
                /* eslint-disable-next-line no-console */
                console.error(
                  `/!\\ Something wrong happened while retrieving data for '${key}' from Prismic page '${value}' :'( - ${response.toString()} (remaining retries: ${retries})`,
                )
                return false
              }
            })
            .catch((error: any) => {
              /* eslint-disable-next-line no-console */
              console.error(
                `/!\\ Something wrong happened while retrieving data for '${key}' from Prismic page '${value}' :'( - ${error.toString()} (remaining retries: ${retries})`,
              )
              return false
            })
        }
      }),
    )

    // module 'advantages_push'
    const advantagesPushSlice = rootState.prismic.stepsData.family?.body?.find(
      (slice: any) => slice.slice_type === 'advantages_push',
    ) as PrismicAdvantagesPushSlice

    const advantagesPushModuleId =
      advantagesPushSlice?.primary?.advantage_module?.id

    if (advantagesPushModuleId) {
      const module = 'advantage_module'

      /* eslint-disable-next-line no-console */
      // console.debug(`Retrieve data from Prismic module '${module}'...`)

      await nuxtApp.$prismic
        .getByID(advantagesPushModuleId)
        .then((response: any) => {
          if (response) {
            const { data }: { data: PrismicAdvantagesPushModule } = response

            dispatch(
              'prismic/setAdvantagesPush',
              formatAdvantagesPushComponentPayload(advantagesPushSlice, data),
            )
          } else {
            /* eslint-disable-next-line no-console */
            console.error(
              `/!\\ Something wrong happened while retrieving data from Prismic module '${module}' :'(`,
            )
          }
        })
    } else {
      /* eslint-disable-next-line no-console */
      console.error(
        "/!\\ Something wrong happened while retrieving module ID for 'advantages_push' :'(",
      )
    }

    // module 'ratings_push' for 'tgps_family'
    const ratingsPushSlice = rootState.prismic.stepsData.family?.body?.find(
      (slice: any) => slice.slice_type === 'ratings_push',
    ) as PrismicRatingsPushSlice

    const ratingsPushModuleId = ratingsPushSlice?.primary?.ratings?.id

    if (ratingsPushModuleId) {
      const module = 'ratings_push_module'

      /* eslint-disable-next-line no-console */
      // console.debug(`Retrieve data from Prismic module '${module}'...`)

      await nuxtApp.$prismic
        .getByID(ratingsPushModuleId)
        .then((response: any) => {
          if (response) {
            const { data } = response

            commit('prismic/setRatingsPushOnFamily', data)
          } else {
            /* eslint-disable-next-line no-console */
            console.error(
              `/!\\ Something wrong happened while retrieving data from Prismic module '${module}' :'(`,
            )
          }
        })
    } else {
      /* eslint-disable-next-line no-console */
      console.error(
        "/!\\ Something wrong happened while retrieving module ID for 'ratings_push' :'(",
      )
    }

    // module 'contact_push' for 'tgps_family'
    const contactPushSlice = rootState.prismic.stepsData.family?.body?.find(
      (slice: any) => slice.slice_type === 'contact_push',
    ) as PrismicContactPushSlice

    if (contactPushSlice && contactPushSlice.items) {
      const module = 'contact_module'

      for (const item of contactPushSlice.items) {
        /* eslint-disable-next-line no-console */
        // console.debug(`Retrieve data from Prismic module '${module}'...`)

        if (!item.contact || !item.contact.id) {
          /* eslint-disable-next-line no-console */
          console.error(`No contact ID for item '${JSON.stringify(item)}'...`)
          continue
        }

        await nuxtApp.$prismic
          .getByID(item.contact.id)
          .then((response: any) => {
            if (response) {
              const { data } = response

              const contact: any = {
                subhead: data.contact_subhead,
                number: {
                  label: data.contact_phone_label,
                  tel: data.contact_phone_number,
                },
                info: nuxtApp.prismicPlugin.asHTML(data.contact_description),
              }

              if (item.contact_cta_label) {
                contact.cta = {
                  href: item.contact_cta_href?.url,
                  label: item.contact_cta_label,
                  external: 'blank',
                }
              }

              if (item.contact_cta_secondary_label) {
                contact.ctaSecondary = {
                  href: item.contact_cta_secondary_href?.url,
                  label: item.contact_cta_secondary_label,
                  external: 'blank',
                }
              }

              commit('prismic/setContactPushOnFamily', contact)
            } else {
              /* eslint-disable-next-line no-console */
              console.error(
                `/!\\ Something wrong happened while retrieving data from Prismic module '${module}' :'(`,
              )
            }
          })
      }
    } else {
      /* eslint-disable-next-line no-console */
      console.error(
        "/!\\ Something wrong happened while retrieving items for 'contact_push' :'(",
      )
    }
    // FETCH data for mandatorySchemes
    const mandatorySchemesUrl =
      rootState.prismic.stepsData.regime &&
      rootState.prismic.stepsData.regime['regimes-json']?.cdn_link
    // FETCH data for offers labels
    const offersLabelsUrl =
      rootState.prismic.stepsData.offers &&
      rootState.prismic.stepsData.offers.labels_json?.cdn_link
    // FETCH data for offers contents
    const offersContentsUrl =
      rootState.prismic.stepsData.offers &&
      rootState.prismic.stepsData.offers.contents_json?.cdn_link
    // FETCH data for offers options
    const optionsContentsUrl =
      rootState.prismic.stepsData.panier &&
      rootState.prismic.stepsData.panier.options_json?.cdn_link

    const comparatorLabelsUrl =
      rootState.prismic.stepsData.offers &&
      rootState.prismic.stepsData.offers.comparator_labels_json?.cdn_link

    const jsonFiles = {
      mandatorySchemes: {
        url: mandatorySchemesUrl,
        fallback: () => import('~/data/professional_status.json'),
      },
      offersLabels: {
        url: offersLabelsUrl,
        fallback: () => import('~/data/offers/labels.json'),
      },
      offersContents: {
        url: offersContentsUrl,
        fallback: () => import('~/data/offers/content.json'),
      },
      optionsContents: {
        url: optionsContentsUrl,
        fallback: () => import('~/data/offers/options.json'),
      },
      comparatorLabelsUrl: {
        url: comparatorLabelsUrl,
        fallback: () => import('~/data/offers/comparator_labels.json'),
      },
    } as {
      [key: string]: {
        url: string
        fallback: () => any
        data?: object[]
      }
    }

    await Promise.all(
      Object.entries(jsonFiles).map(async ([key, value]) => {
        if (process.env.DEV_MOCK_OFFERS) {
          /* eslint-disable-next-line no-console */
          // console.debug(`Retrieve JSON data for '${key}' using mock data...`)
          jsonFiles[key].data = await value
            .fallback()
            .then((m: any) => m.default || m)

          return
        }

        if (!value.url) {
          /* eslint-disable-next-line no-console */
          console.error(
            `/!\\ Missing file URL for JSON '${key}': using fallback file...`,
          )
          jsonFiles[key].data = await value
            .fallback()
            .then((m: any) => m.default || m)

          return
        }

        /* eslint-disable-next-line no-console */
        // console.debug(
        //   `Retrieve JSON data for '${key}' from file URL '${value.url}'...`
        // )

        return await fetch(value.url)
          .then((response) => {
            if (response.ok) return response.json()

            let error: Promise<any>

            try {
              error = response.json()
            } catch (_error) {
              error = response.text()
            }

            return error.then((response: any) => {
              throw new Error(`Error from DAM`, { cause: response })
            })
          })
          .then((response: any) => {
            if (response) {
              jsonFiles[key].data = response
            }
          })
          .catch(async (error) => {
            /* eslint-disable-next-line no-console */
            console.error(
              `/!\\ Something wrong happened while retrieving data for '${key}' from '${
                value.url
              }' :'( - ${error.toString()} - ${JSON.stringify(error.cause)}`,
            )

            return (jsonFiles[key].data = await value
              .fallback()
              .then((m: any) => m.default || m))
          })
      }),
    )

    const sortedMandatorySchemes =
      (jsonFiles.mandatorySchemes.data as MandatoryScheme[])
        ?.sort((a, b) => a.order - b.order)
        .map((item) => ({
          ...item,
          value: item.id,
        })) || []

    commit('prismic/setMandatorySchemes', sortedMandatorySchemes)
    commit('prismic/setOffersLabels', jsonFiles.offersLabels.data)
    commit('prismic/setOffersContents', jsonFiles.offersContents.data)
    commit('prismic/setOffersOptions', jsonFiles.optionsContents.data)
    commit(
      'prismic/setOffersComparatorLabels',
      jsonFiles.comparatorLabelsUrl.data,
    )
  },
}

export default actions
