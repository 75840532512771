import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/vercel/path0/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/.nuxt/sentry-client-config.mjs";
import plugin_client_1uFu2M4PVa from "/vercel/path0/node_modules/@nuxtjs/prismic/dist/runtime/plugin.client.js";
import plugin_PWMUhiA9tt from "/vercel/path0/node_modules/@nuxtjs/prismic/dist/runtime/plugin.js";
import i18n_yfWm7jX06p from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_tbFNToZNim from "/vercel/path0/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import axeptio_6MeDWmepKK from "/vercel/path0/plugins/axeptio.ts";
import gtm_client_OzmBuHLRIb from "/vercel/path0/plugins/gtm.client.ts";
import cookies_rCS2psPm8j from "/vercel/path0/plugins/cookies.ts";
import vuex_F8JCgj45hr from "/vercel/path0/plugins/vuex.ts";
import persist_store_XRbKV84BW6 from "/vercel/path0/plugins/persist-store.ts";
import directives_8CcCirWtnE from "/vercel/path0/plugins/directives.ts";
import event_bus_S6Iq1iGe5d from "/vercel/path0/plugins/event-bus.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_client_1uFu2M4PVa,
  plugin_PWMUhiA9tt,
  i18n_yfWm7jX06p,
  plugin_tbFNToZNim,
  axeptio_6MeDWmepKK,
  gtm_client_OzmBuHLRIb,
  cookies_rCS2psPm8j,
  vuex_F8JCgj45hr,
  persist_store_XRbKV84BW6,
  directives_8CcCirWtnE,
  event_bus_S6Iq1iGe5d,
  sentry_client_shVUlIjFLk
]