<template>
  <div id="popin-global-error">
    <molecules-popin
      v-if="popinPayload"
      ref="popin-global-error"
      :is-dismissable="dismissable"
    >
      <div class="confirmation-block">
        <h2 class="confirmation-block-title">{{ popinPayload.block.title }}</h2>
        <div class="edito">{{ popinPayload.block.text }}</div>
        <atoms-cta-link
          class="cta-link"
          href="/"
          :is-primary="true"
          @click="goToHome"
          >Retourner à la page d'accueil</atoms-cta-link
        >
      </div>
    </molecules-popin>
  </div>
</template>

<script lang="ts">
import { goTo, type Router } from '~/utils/navigation'

export default defineNuxtComponent({
  name: 'PopinGlobError',
  props: {
    dismissable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    popinPayload() {
      return {
        block: {
          title: 'Une erreur est survenue',
          text: 'Nos équipes ont été prévenues et travaillent activement au rétablissement du service. Veuillez réessayer ultérieurement.',
        },
      }
    },
  },
  mounted() {
    this.handleToggle()
  },
  methods: {
    handleToggle() {
      ;(this.$refs['popin-global-error'] as any).handleToggle()
    },
    goToHome() {
      goTo(this.router as unknown as Router, '/')
    },
  },
})
</script>

<style lang="scss"></style>
