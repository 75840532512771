<template>
  <img
    :srcset="filename"
    :sizes="sizes"
    :width="width"
    :height="height"
    :alt="alt"
    :fetchpriority="fetchpriority"
    class="fluid-img"
  />
</template>

<script>
export default defineNuxtComponent({
  name:'ci-img',
  props: {
    filename: {
      type: String,
      required: true,
    },
    // is used by CloudImage only
    fixed: {
      type: Boolean,
      required: false,
    },
    // is used by CloudImage only
    singleSrc: {
      type: Boolean,
      required: false,
    },
    sizes: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      required: true,
      default: '',
    },
    height: {
      type: Number,
      required: true,
      default: null,
    },
    width: {
      type: Number,
      required: true,
      default: null,
    },
    // is used by CloudImage only
    lazy: {
      type: Boolean,
      required: false,
    },
    fetchpriority: {
      type: String,
      required: false,
      default: 'auto',
      validator: (value) => {
        return ['auto', 'high', 'low'].includes(value)
      },
    },
  },
})
</script>

<style>
img.fluid-img {
  width: 100%;
  height: auto;
}
</style>
