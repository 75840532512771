import { default as PrismicPreviewJ01p9R2IH8Meta } from "/vercel/path0/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue?macro=true";
import { default as _91orderId_9382CWDchpiIMeta } from "/vercel/path0/pages/adhesion-validee/[orderId].vue?macro=true";
import { default as adressec7322GXfwYMeta } from "/vercel/path0/pages/adresse.vue?macro=true";
import { default as assureur_45a_45resilierXLgc9XHVjoMeta } from "/vercel/path0/pages/assureur-a-resilier.vue?macro=true";
import { default as confirmation_45smsPkU0yURMh4Meta } from "/vercel/path0/pages/confirmation-sms.vue?macro=true";
import { default as conjointaEBqX4nTwIMeta } from "/vercel/path0/pages/conjoint.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as couverture_45actuelleRLJQ6LEEFoMeta } from "/vercel/path0/pages/couverture-actuelle.vue?macro=true";
import { default as date_45effetNk7oL9c0DPMeta } from "/vercel/path0/pages/date-effet.vue?macro=true";
import { default as date_45naissanceZa2J0oL0mWMeta } from "/vercel/path0/pages/date-naissance.vue?macro=true";
import { default as detail_45adresseZVoI6GfKYLMeta } from "/vercel/path0/pages/detail-adresse.vue?macro=true";
import { default as _projectIdB0wBwgW3TDMeta } from "/vercel/path0/pages/devis/_projectId.vue?macro=true";
import { default as _91projectId_93qrXdfZAn7hMeta } from "/vercel/path0/pages/devis/[projectId].vue?macro=true";
import { default as _91orderId_936w6ZchuxpIMeta } from "/vercel/path0/pages/emailconfirmation/[orderId].vue?macro=true";
import { default as enfants2oC1gIbGtHMeta } from "/vercel/path0/pages/enfants.vue?macro=true";
import { default as familleSXM2AVdceJMeta } from "/vercel/path0/pages/famille.vue?macro=true";
import { default as ibanEUOKbOCU1wMeta } from "/vercel/path0/pages/iban.vue?macro=true";
import { default as madelinEAp1Gd8gRyMeta } from "/vercel/path0/pages/madelin.vue?macro=true";
import { default as niveaux_45couvertureuL4HxeOociMeta } from "/vercel/path0/pages/niveaux-couverture.vue?macro=true";
import { default as _91projectId_93xzAg9nU575Meta } from "/vercel/path0/pages/offres/[projectId].vue?macro=true";
import { default as indexWadzvIspV5Meta } from "/vercel/path0/pages/offres/index.vue?macro=true";
import { default as prelevementsXCdNQxj2X0Meta } from "/vercel/path0/pages/prelevements.vue?macro=true";
import { default as regimexOxa8bWf53Meta } from "/vercel/path0/pages/regime.vue?macro=true";
import { default as souscription_45assure_45principalGpkOXkdNruMeta } from "/vercel/path0/pages/souscription-assure-principal.vue?macro=true";
import { default as souscription_45conjointHxt7uCI4zjMeta } from "/vercel/path0/pages/souscription-conjoint.vue?macro=true";
import { default as _91page_93nB5cz4GzyOMeta } from "/vercel/path0/pages/souscription-enfant/[page].vue?macro=true";
import { default as _91projectId_93JNn0P0shG7Meta } from "/vercel/path0/pages/souscription/[projectId].vue?macro=true";
import { default as statut_45pro05lgBzBgNSMeta } from "/vercel/path0/pages/statut-pro.vue?macro=true";
import { default as testlK5QpQxp8eMeta } from "/vercel/path0/pages/test.vue?macro=true";
export default [
  {
    name: "prismic-preview",
    path: "/preview",
    component: () => import("/vercel/path0/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue")
  },
  {
    name: "adhesion-validee-orderId___fr-fr",
    path: "/adhesion-validee/:orderId()",
    component: () => import("/vercel/path0/pages/adhesion-validee/[orderId].vue")
  },
  {
    name: "adresse___fr-fr",
    path: "/adresse",
    component: () => import("/vercel/path0/pages/adresse.vue")
  },
  {
    name: "assureur-a-resilier___fr-fr",
    path: "/assureur-a-resilier",
    component: () => import("/vercel/path0/pages/assureur-a-resilier.vue")
  },
  {
    name: "confirmation-sms___fr-fr",
    path: "/confirmation-sms",
    component: () => import("/vercel/path0/pages/confirmation-sms.vue")
  },
  {
    name: "conjoint___fr-fr",
    path: "/conjoint",
    component: () => import("/vercel/path0/pages/conjoint.vue")
  },
  {
    name: "contact___fr-fr",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "couverture-actuelle___fr-fr",
    path: "/couverture-actuelle",
    component: () => import("/vercel/path0/pages/couverture-actuelle.vue")
  },
  {
    name: "date-effet___fr-fr",
    path: "/date-effet",
    component: () => import("/vercel/path0/pages/date-effet.vue")
  },
  {
    name: "date-naissance___fr-fr",
    path: "/date-naissance",
    component: () => import("/vercel/path0/pages/date-naissance.vue")
  },
  {
    name: "detail-adresse___fr-fr",
    path: "/detail-adresse",
    component: () => import("/vercel/path0/pages/detail-adresse.vue")
  },
  {
    name: "devis-_projectId___fr-fr",
    path: "/devis/_projectId",
    component: () => import("/vercel/path0/pages/devis/_projectId.vue")
  },
  {
    name: "devis-projectId___fr-fr",
    path: "/devis/:projectId()",
    component: () => import("/vercel/path0/pages/devis/[projectId].vue")
  },
  {
    name: "emailconfirmation-orderId___fr-fr",
    path: "/emailconfirmation/:orderId()",
    component: () => import("/vercel/path0/pages/emailconfirmation/[orderId].vue")
  },
  {
    name: "enfants___fr-fr",
    path: "/enfants",
    component: () => import("/vercel/path0/pages/enfants.vue")
  },
  {
    name: "famille___fr-fr",
    path: "/famille",
    component: () => import("/vercel/path0/pages/famille.vue")
  },
  {
    name: "iban___fr-fr",
    path: "/iban",
    component: () => import("/vercel/path0/pages/iban.vue")
  },
  {
    name: "madelin___fr-fr",
    path: "/madelin",
    component: () => import("/vercel/path0/pages/madelin.vue")
  },
  {
    name: "niveaux-couverture___fr-fr",
    path: "/niveaux-couverture",
    component: () => import("/vercel/path0/pages/niveaux-couverture.vue")
  },
  {
    name: "offres-projectId___fr-fr",
    path: "/offres/:projectId()",
    component: () => import("/vercel/path0/pages/offres/[projectId].vue")
  },
  {
    name: "offres___fr-fr",
    path: "/offres",
    component: () => import("/vercel/path0/pages/offres/index.vue")
  },
  {
    name: "prelevements___fr-fr",
    path: "/prelevements",
    component: () => import("/vercel/path0/pages/prelevements.vue")
  },
  {
    name: "regime___fr-fr",
    path: "/regime",
    component: () => import("/vercel/path0/pages/regime.vue")
  },
  {
    name: "souscription-assure-principal___fr-fr",
    path: "/souscription-assure-principal",
    component: () => import("/vercel/path0/pages/souscription-assure-principal.vue")
  },
  {
    name: "souscription-conjoint___fr-fr",
    path: "/souscription-conjoint",
    component: () => import("/vercel/path0/pages/souscription-conjoint.vue")
  },
  {
    name: "souscription-enfant-page___fr-fr",
    path: "/souscription-enfant/:page()",
    component: () => import("/vercel/path0/pages/souscription-enfant/[page].vue")
  },
  {
    name: "souscription-projectId___fr-fr",
    path: "/souscription/:projectId()",
    component: () => import("/vercel/path0/pages/souscription/[projectId].vue")
  },
  {
    name: "statut-pro___fr-fr",
    path: "/statut-pro",
    component: () => import("/vercel/path0/pages/statut-pro.vue")
  },
  {
    name: "test___fr-fr",
    path: "/test",
    component: () => import("/vercel/path0/pages/test.vue")
  }
]