import { Context } from '@nuxt/types'
import {
  age,
  conjoint,
  formuleChoisie,
  hashEmail,
  libelleEtape,
  marche,
  nbEnfants,
  needsValue,
  prime,
  projectId,
} from '~/utils/tracking'
import { type Offer } from '~/types/offers'
import { defineNuxtPlugin } from 'nuxt/app'

const EVENT = 'evenement_web'
const EVENT_CATEGORIE = 'TGP Santé'

enum EVENT_TYPE {
  actionInternaute = 'action_internaute',
  pageVue = 'page_vue',
}

type ConversionLabel =
  | 'Lead - optin'
  | 'MER - devis'
  | 'MER - devis actualisé'
  | 'MER - souscrire'
  | 'Adhésion validée'

const getContext = () => {
  const context = useNuxtApp()
  const route = useRoute()
  const store = context.store || context.$store
  const $gtm = useGtm()

  return { route, store, $gtm }
}

export const pushCtaClick = (
  { action, label }: { action?: string; label: string },
  _el?: HTMLElement
) => {
  const { $gtm, store } = getContext()
  const { state } = store

  const data = {
    event: EVENT,
    event_type: EVENT_TYPE.actionInternaute,
    event_categorie: EVENT_CATEGORIE,
    event_action: action || 'Clic CTA',
    event_libelle: label,
    marche: marche(state),
    project_id: projectId(state),
  }

  if (!$gtm) {
    console.warn('pushCtaClick GTM not available')
    return
  }

  $gtm.push(data)
}

export const pushConversion = async ({ label }: { label: ConversionLabel }) => {
  const { $gtm, store } = getContext()
  const { state } = store

  const data = {
    event: EVENT,
    event_type: EVENT_TYPE.actionInternaute,
    event_categorie: EVENT_CATEGORIE,
    event_action: 'Conversion',
    event_libelle: label,
    marche: marche(state),
    projet_id: projectId(state),
    nb_enfants: nbEnfants(state),
    age: age(state),
    conjoint: conjoint(state),
    formule_choisie: formuleChoisie(state),
    prime_annuelle_ttc: prime(state),
    cle_adloop: await hashEmail(state),
  }

  if (!$gtm) {
    console.warn('pushConversion GTM not available')
    return
  }

  $gtm.push(data)
}

export const pushNeeds = () => {
  const { $gtm, store } = getContext()
  const { state } = store

  const data = {
    event: EVENT,
    event_type: EVENT_TYPE.actionInternaute,
    event_categorie: EVENT_CATEGORIE,
    event_action: 'Clic CTA',
    event_libelle: 'Choix des niveaux de couverture',
    marche: marche(state),
    projet_id: projectId(state),
    jauge_soins_courants: needsValue(state, 'medicalExpenses'),
    jauge_hospitalisation: needsValue(state, 'hospitalization'),
    jauge_optique: needsValue(state, 'optical'),
    jauge_dentaire: needsValue(state, 'dental'),
    jauge_aides_auditives: needsValue(state, 'hearingAid'),
    jauge_medecines_douces: needsValue(state, 'naturalMedecine'),
  }

  if (!$gtm) {
    console.warn('pushNeeds GTM not available')
    return
  }

  $gtm.push(data)
}

export const pushDocument = ({ documentName }: { documentName: string }) => {
  const { $gtm, store } = getContext()
  const { state } = store

  const data = {
    event: EVENT,
    event_type: EVENT_TYPE.actionInternaute,
    event_categorie: EVENT_CATEGORIE,
    event_action: 'Clic CTA',
    event_libelle: `Téléchargement - ${documentName}`,
    marche: marche(state),
    projet_id: projectId(state),
  }

  if (!$gtm) {
    console.warn('pushDocument GTM not available')
    return
  }

  $gtm.push(data)
}

export const pushOffers = ({ offers }: { offers?: Offer[] }) => {
  if (!offers || !offers.length) return

  const { $gtm, store } = getContext()
  const { state } = store

  const data = {
    event: EVENT,
    event_type: EVENT_TYPE.actionInternaute,
    event_categorie: EVENT_CATEGORIE,
    event_action: 'Visibilité élément',
    event_libelle: `${offers.length} offre(s) affichée(s)`,
    offres_affichees: offers
      .map((o) => `${o.marketingProductId}-${o.levelCode}`)
      .join('&'),
    marche: marche(state),
  }

  if (!$gtm) {
    console.warn('pushOffers GTM not available')
    return
  }

  $gtm.push(data)
}

export const pushNoOffer = () => {
  const { $gtm, store } = getContext()
  const { state } = store

  const data = {
    event: EVENT,
    event_type: EVENT_TYPE.actionInternaute,
    event_categorie: EVENT_CATEGORIE,
    event_action: 'Visibilité élément',
    event_libelle: `0 offre affichée`,
    offres_affichees: '',
    marche: marche(state),
  }
  if (!$gtm) {
    console.warn('pushNoOffer GTM not available')
    return
  }

  $gtm.push(data)
}

export const pushDateEffetUpdatedDisplayed = () => {
  const { $gtm, store } = getContext()
  const { state } = store

  const data = {
    event: EVENT,
    event_type: EVENT_TYPE.actionInternaute,
    event_categorie: EVENT_CATEGORIE,
    event_action: 'Visibilité élément',
    event_libelle: "date d'effet mise à jour",
    marche: marche(state),
    project_id: projectId(state),
  }

  if (!$gtm) {
    console.warn('pushDateEffetUpdatedDisplayed GTM not available')
    return
  }

  $gtm.push(data)
}

export const pushPageView = ({ path }: { path?: string } = {}) => {
  const { $gtm, route, store } = getContext()
  const { state } = store

  let formalPath =
    path || route.matched[0]?.path?.replace('?', '') || route.path

  // for this dynamic route, use path instead
  if (formalPath === '/souscription-enfant/:index') {
    formalPath = route.path
  }

  const pageViewData = {
    event: EVENT,
    event_type: EVENT_TYPE.pageVue,
    marche: marche(state),
    page_virtuelle: `/page-virtuelle/tgp_sante/${libelleEtape(formalPath)}`,
  }

  if (!$gtm) {
    console.warn('pushPageView GTM not available')
    return
  }

  $gtm.push(pageViewData)

  const data = {
    event: EVENT,
    event_type: EVENT_TYPE.actionInternaute,
    event_categorie: EVENT_CATEGORIE,
    event_action: 'Atteinte étape',
    event_libelle: libelleEtape(formalPath),
    marche: marche(state),
    projet_id: projectId(state),
  }

  $gtm.push(data)
}

export default defineNuxtPlugin({
  setup(nuxtApp) {
    const gtm = useGtm()

    if (!gtm) return

    gtm.enable()

    let handleEvent: EventListenerOrEventListenerObject

    function attachEvent(el, binding) {
      const { arg, value } = binding
      if (!arg || !value) return

      // remove existing listener before attaching new one
      el.removeEventListener(arg, el.event, true)

      let fn = () => pushCtaClick(value, el)

      // store the event handle function reference to be used in the unbind callback
      el.event = fn
      // set useCapture to true to ensure the directive listener triggers first and to avoid bubbling
      el.addEventListener(arg, fn, true)
    }

    // bind tracking fired from components of the library
    nuxtApp.vueApp.directive('track', {
      // bind only executes once
      mounted: attachEvent,
      // update event listener when component is updated, this is necessary to support dynamic value,
      // without it, only the initial value will be registered and any changes won't be reflected.
      updated: attachEvent,
      unmounted: (el, { arg }) => {
        if (!arg || !el.event) return
        el.removeEventListener(arg, el.event, true)
      },
    })

    // bind tracking from clicks on specific links
    document.addEventListener(
      'click',
      (e) => {
        if (!e || !e.target) return

        const anchor = (e.target as HTMLElement).closest('a')

        if (!anchor) return

        const href = anchor.href || ''

        if (href.includes('ekomi.fr')) {
          pushCtaClick({ label: 'Lire les avis' }, anchor)
        }

        if (href.includes('tel:')) {
          pushCtaClick({ label: 'Téléphone' }, anchor)
        }
      },
      false
    )

    return {
      provide: {
        gtm,
      },
    }
  },
  hooks: {
    // You can directly register Nuxt app runtime hooks here
    'app:created'() {
      const nuxtApp = useNuxtApp()
      pushPageView()

      // do something in the hook
    },
  },
})
