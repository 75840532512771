
// @ts-nocheck


export const localeCodes =  [
  "fr-fr"
]

export const localeLoaders = {
  "fr-fr": []
}

export const vueI18nConfigs = [
  () => import("../i18n.config.js?hash=c231e734&config=1" /* webpackChunkName: "i18n_config_c231e734" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "fr-fr",
      "name": "France"
    }
  ],
  "defaultLocale": "fr-fr",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": false
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "messages": {
    "fr": {
      "forms": {
        "date": {
          "mask": "d/`m/`Y",
          "regexp": "(?<day>[0-9]{2})/(?<month>[0-9]{2})/(?<year>[0-9]{4})"
        }
      },
      "global": {
        "logo": "April"
      },
      "header": {
        "contact": {
          "description": "Du lundi au vendredi de 9h à 19h - Le samedi de 10h à 16h",
          "label": "04 78 92 88 19 *",
          "mention": "* prix d'un appel local depuis la métropole",
          "phone": "+33478928819"
        },
        "ratingLabel": "{count} avis clients <a href=\"https://www.ekomi.fr/avis-clients-april-fr.html\" target=\"_blank\">sur eKomi</a>"
      },
      "lisaAutocomplete": {
        "defaultGroupLabel": ""
      },
      "slider": {
        "next": "Suivant",
        "previous": "Précédent"
      },
      "steps": {
        "home": "Obtenir un tarif"
      },
      "enfants": {
        "child0": "Premier enfant",
        "child1": "Deuxième enfant",
        "child2": "Troisième enfant",
        "child3": "Quatrième enfant",
        "child4": "Cinquième enfant",
        "child5": "Sixième enfant"
      },
      "drawerProfil": {
        "peopleComposition": "Qui doit-on assurer ?",
        "peopleCompositionValue": {
          "alone": "Vous",
          "couple": "Votre couple",
          "aloneChild": "Vous et vos enfants",
          "coupleChild": "Votre couple et vos enfants",
          "undefined": ""
        },
        "hasPolicyInsurance": "Actuellement couvert",
        "hasPolicyInsuranceYear": "Actuellement couvert depuis plus d’un an",
        "projectSituation": {
          "yes": "Oui",
          "no": "Non"
        },
        "effectiveDate": "Début de contrat souhaité",
        "birthdate": "Date de naissance",
        "mandatoryScheme": "Régime social",
        "statutPro": "Statut professionnel",
        "spouse": "Conjoint",
        "child0": "1er enfant",
        "child1": "2eme enfant",
        "child2": "3eme enfant",
        "child3": "4eme enfant",
        "child4": "5eme enfant",
        "child5": "6eme enfant",
        "address": "Adresse",
        "contact": "Coordonnées"
      },
      "recap": {
        "options": {
          "singular": "Option ajoutée",
          "plural": "Options ajoutées"
        },
        "price": "<span class=\"amount\"><strong>{price} €</strong></span> /mois<sup>(1)</sup>",
        "madelin": {
          "hasMadelin": "Vous bénéficiez de la déduction Madelin",
          "noMadelin": "Vous ne bénéficiez pas de la déduction Madelin"
        },
        "discount": {
          "hasDiscount": "Un mois offert<sup>(2)</sup>, soit {discountedYearlyPrice} la première année puis {yearlyPrice} par an.",
          "noDiscount": "Soit {yearlyPrice} € / an"
        },
        "levels": {
          "healAndHospitalisation": "Soins courants et hospitalisation",
          "dentalAndOptical": "Dentaire et optique",
          "level": "niveau"
        },
        "contractStartDate": "Début de contrat"
      }
    }
  },
  "parsePages": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "fr-fr",
    "name": "France",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
