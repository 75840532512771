<template>
  <organisms-site-header-light-v2
    id="header-april"
    :stepper="stepper"
    :contact="contact"
    :current-step="currentStep"
    :ratings="ratings"
    :nb-step="nbStep"
    :root-link="rootLink"
  />
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import { EkomiPayload } from '~/api-clients/ekomi'

export default defineNuxtComponent({
  name: 'AprilHeader',
  props: {
    hiddenHeader: {
      type: Boolean,
    },
    hideStepper: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const ekomiInfo: EkomiPayload['info'] | undefined =
      this.$store.state.index.ekomi.info
    const i18n = useI18n()

    return {
      stepper: true,
      contact: {
        label: i18n.t('header.contact.label'),
        phone: i18n.t('header.contact.phone'),
        description: i18n.t('header.contact.description'),
        mention: i18n.t('header.contact.mention'),
        variant: 'right',
        showDescription: false,
      },
      ratings: ekomiInfo && {
        rating: parseFloat(ekomiInfo!.fb_avg),
        showTex: true,
        ratingLabel: i18n.t('header.ratingLabel', {
          count: ekomiInfo!.fb_count,
        }),
      },
      rootLink: {
        href: 'https://www.april.fr/complementaire-sante',
        external: 'true',
        target: '_blank',
      },
    }
  },
  computed: {
    ...(mapGetters({
      currentStep: 'steps/currentStepNumber',
      nbStep: 'steps/nbSteps',
    }) as {
      currentStep: () => number
      nbStep: () => number
    }),
  },
  watch: {
    hiddenHeader: function () {
      if (this.hiddenHeader) return

      // when hiddenHeader is false, ensure there's no class 'hidden-header'
      this.toggleClassHiddenHeader(false)
    },
    hideStepper: function () {
      if (this.hideStepper) {
        this.toggleHideStepper()
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)

    if (this.hideStepper) {
      this.toggleHideStepper()
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      if (!this.hiddenHeader) {
        return
      }

      const currentScroll = window.scrollY

      if (currentScroll > 150) {
        this.toggleClassHiddenHeader(true)
      } else {
        this.toggleClassHiddenHeader(false)
      }
    },
    toggleClassHiddenHeader(value: boolean) {
      const header = document.getElementById('header-april')
      const toggleClass = 'hidden-header'

      if (value) {
        header?.classList.add(toggleClass)
      } else {
        header?.classList.remove(toggleClass)
      }
    },

    toggleHideStepper() {
      const header = document.getElementById('header-april')
      const toggleClass = 'hidden-stepper'

      header?.classList.add(toggleClass)
    },
  },
})
</script>

<style lang="scss">
.site-header-light-v2 {
  &.hidden-stepper {
    .progress-bar {
      display: none;
    }
  }

  &.hidden-header {
    transform: translateY(-100%);
    transition: transform 0.5s ease-in-out;

    .progress-bar {
      transform: translateY(-100%);
      transition: transform 0.5s ease-in-out;
    }
  }
}
</style>
