import type { RouterConfig } from '@nuxt/schema'
import type { RouteRecordRaw } from 'vue-router'

const comparateurRoutesNames = [
  'adresse',
  'couverture-actuelle',
  'date-effet',
  'madelin',
]

const completeComparateurRoutes = comparateurRoutesNames.map((page: string) => {
  const alias = `comparateur-${page}`
  return {
    name: alias,
    path: `/${alias}`,
    component: () => import(`~/pages/${page}.vue`).then((r) => r.default || r),
  }
})
const routesWithOffersLayout = [
  '/offres',
  '/recapitulatif',
  '/options',
  '/adhesion-validee/:orderId()',
  '/offres/:projectId()',
  '/panier',
  '/souscription/:projectId()',
]

const getRoutesWithComponents = (routes: readonly RouteRecordRaw[]) => {
  return routes.map((route) => {
    if (routesWithOffersLayout.includes(route.path))
      return {
        name: route.name,
        path: route.path,
        component: route.component,
        meta: { layout: 'offers' },
      }

    return {
      name: route.name,
      path: route.path,
      component: route.component,
    }
  })
}

export default <RouterConfig>{
  // https://router.vuejs.org/api/interfaces/routeroptions.html#routes
  routes: (_routes) => {
    const routesWithImportedComponent = getRoutesWithComponents(_routes)
    return [
      {
        name: 'index',
        path: '/',
        component: () =>
          import('~/pages/famille.vue').then((r) => r.default || r),
      },
      {
        name: 'souscription',
        path: '/souscription',
        redirect: '/',
      },
      ...completeComparateurRoutes,
      ...routesWithImportedComponent,
    ]
  },
}
