import { plugin as adUIPlugin } from 'UI/store/ui/plugin'
import { Store, createStore } from 'vuex'
import { defineNuxtPlugin } from 'nuxt/app'
import * as index from '../store/index'
import * as ui from '../store/ui'
import * as prismic from '../store/prismic'
import * as steps from '../store/steps'
import * as form from '../store/form'
import * as tgp from '../store/tgp'

export default defineNuxtPlugin((nuxtApp) => {
  const store = createStore({
    modules: {
      index,
      steps: {
        namespaced: true,
        ...steps,
      },
      form: {
        namespaced: true,
        ...form,
      },
      tgp: {
        namespaced: true,
        ...tgp,
      },
      ui: {
        namespaced: true,
        ...ui,
      },
      prismic: {
        namespaced: true,
        ...prismic,
      },
    },
    plugins: [adUIPlugin],
  })
  nuxtApp.vueApp.use(store)

  if (typeof window === 'undefined') {
    nuxtApp.payload.vuex = store.state
  } else if (nuxtApp.payload && nuxtApp.payload.vuex) {
    store.replaceState(nuxtApp.payload.vuex)
  }

  return {
    provide: {
      store,
    },
  }
})
