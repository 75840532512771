// This file mount the module UI for the Vuex store
import { plugin as adPlugin } from 'UI/store/ui/plugin'
import actions from './actions.ts'
import getters from './getters.ts'
import mutations from './mutations.ts'

const plugins = [adPlugin]

export { plugins, getters, mutations, actions }

export const modules = {
}
