import { OfferContent } from '~/types/offers'

// TODO: type this
const state = () => ({
  advantagesPush: undefined,
  footerData: {},
  mandatorySchemes: [],
  offers: {
    contents: [],
    labels: [],
    comparatorLabels: [],
  },
  stepsData: {
    adresse: undefined,
    detailAdresse: undefined,
    cancellation: undefined,
    coverLevels: undefined,
    children: undefined,
    dateEffet: undefined,
    dateEffetDays: undefined,
    dateNaissance: undefined,
    documents: undefined,
    family: {},
    iban: undefined,
    madelin: undefined,
    offers: undefined,
    projectSituation: undefined,
    spouse: undefined,
    souscriptionSpouse: undefined,
    panier: undefined,
    regime: undefined,
    subscribeChildren: undefined,
    statutPro: undefined,
  },
})

export type NeedsKeys =
  | 'hospitalization'
  | 'medicalExpenses'
  | 'optical'
  | 'dental'
  | 'hearingAid'
  | 'naturalMedecine'

export type LeadFilter = {
  age_min: number
  age_max: number
  source_payant: boolean
  source_naturel: boolean
}

export type PrismicState = {
  advantagesPush?: AdvantagesPushPayload
  footerData: {}
  mandatorySchemes: {
    label: string
  }[]
  offers: {
    contents: OfferContent[]
    labels: { [key: string]: string }[]
    options: { [key: string]: string }[]
    comparatorLabels: { UTM_Content: number; Label_comparateur: string }[]
  }
  stepsData: {
    adhesionValidee?: {
      title: string
      title_cb: string
      subtitle: PrismicRichText
      alert_title: string
      alert_text: string
      cards_title: string
      cards: {
        card_image: PrismicDamFile
        card_title: PrismicRichText
        card_text: PrismicRichText
        card_link_label: string
        card_link: {
          url: string
        }
        card_value: string
      }[]
    }
    detailAdresse?: {
      title: PrismicRichText
    }
    adresse?: {
      title: string
    }
    cancellation: {
      title: string
      subtitle: string
    }
    children?: {
      title: string
      children_number: string
      children_birthdate: string
    }
    contact?: {
      title: object[]
      mentions_legales: object[]
      lead_filters: LeadFilter[]
    }
    coverLevels?: {
      title: string
      subtitle: string
      body: {
        items: {
          key: string
          description: object[]
        }[]
        primary: {
          title1: string
          key: NeedsKeys
          icon: string
        }
      }[]
    }
    dateEffet?: {
      title: string
      subtitle_no_contract: string
      subtitle_no_contract_j_2: string
      subtitle_contract_one_year: string
    }
    dateEffetDays?: {
      lundi: string
      mardi: string
      mercredi: string
      jeudi: string
      vendredi: string
      samedi: string
      dimanche: string
    }
    dateNaissance?: {
      title: string
      subtitle_no_contract: string
      subtitle_contract_one_year: string
      body: {
        slice_label: string
        slice_type: string
        items: {
          item_icon: string
          item_image: PrismicDamFile
          item_text: object[]
        }[]
        primary: {
          background: string
          content: object[]
          highlight_icon: string
          highlight_image: PrismicDamFile
          highlight_title: object[]
        }
      }[]
    }
    documents?: {
      title: string
      cards_title: string
      cards: {
        card_image: PrismicDamFile
        card_title: {
          spans: { end: number; start: number; type: string }[]
          text: string
          type: string
        }[]
        card_text: {
          spans: { end: number; start: number; type: string }[]
          text: string
          type: string
        }[]
        card_link_label: string
        card_link: {
          url: string
        }
        card_value: string
      }[]
    }
    family: {
      maintenance_mode: boolean
      title: string
      subtitle: string
      hint: object[]
      rating_push_title: object[]
      contact_push_v2_title: string
      // inputs: {
      //   label: string
      //   value: string
      //   picture: {
      //     alt: string
      //     filename: string
      //   }
      // }[]
      body: (
        | PrismicAdvantagesPushSlice
        | PrismicContactPushSlice
        | PrismicRatingsPushSlice
        | PrismicEditoRichTextSlice
        | PrismicPopinSliceData
      )[]
      ratingsPush: {
        title: object[]
      }
      contactPush: {
        cta: {
          href: string
          label: string
          external: string
        }
        ctaSecondary: {
          href: string
          label: string
          external: string
        }
        subhead: string
        number: {
          label: string
          tel: string
        }
        info: string
      }[]
    }
    iban?: {
      title: string
      subtitle: string
      iban_tooltip: string
      legal_notice: string
    }
    madelin?: {
      title: string
      question_1_title: string
      question_1_hint: string
      question_2_title: string
      question_2_hint: string
    }
    offers?: {
      drawer_profil_title: string
      drawer_profil_subtitle: string
      drawer_needs_title: string
      drawer_needs_subtitle: string
      title_1_offer: string
      title_multiple_offers: string
      subtitle: string
      legal_notice: string
      legal_notice_for_only: PrismicRichText
      loader_text: string
      special_offer: object[]
      contents_json: PrismicDamFile
      labels_json: PrismicDamFile
      comparator_labels_json: PrismicDamFile
      body: {
        slice_label: string
        slice_type: string
        primary: {
          title: string
          text: string
          info?: string
          'primary-cta': string
        }
      }[]
    }
    panier?: {
      title: string
      options_json: PrismicDamFile
      estimate_cta_label: string
      subscribe_cta_label: string
      body: PrismicNotificationCardSliceData[]
    }
    prelevements?: {
      title: PrismicRichText
      frequency_title: PrismicRichText
      payment_day_title: PrismicRichText
    }
    projectSituation?: {
      hint: object[]
      title_1: object[]
      title_2: object[]
    }
    regime?: {
      title: string
      'regimes-json': PrismicDamFile
    }
    spouse?: {
      title: string
      spouse_birthdate: string
      spouse_mandatory_scheme: string
    }
    subscribeChildren?: {
      title_one_child: object[]
      title_children_1: object[]
      title_children_2: object[]
      title_children_3: object[]
      title_children_4: object[]
      title_children_5: object[]
      title_children_6: object[]
      question_1: object[]
      question_2: object[]
      question_2_bis: object[]
    }
    souscriptionSpouse?: {
      title: string
    }
    statutPro?: {
      title: string
    }
    souscription?: {
      title: string
      subtitle: string
      hint: object[]
    }
    signature?: {
      title: string
      subtitle: string
      loader_text: string
      body: {
        slice_label: string
        slice_type: string
        primary: {
          title: string
          text: string
          info: string
          'primary-cta': string
          'primary-cta-href': {
            link_type: string
            url: string
            target: string
          }
          picture: PrismicDamFile
        }
      }[]
    }
  }
}

export default state
