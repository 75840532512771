<template>
  <AprilBody :hide-stepper="true">
    <PopinGlobalError />
  </AprilBody>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    console.error('Error page displayed', this.error)
    const { $sentryCaptureException } = useNuxtApp()

    $sentryCaptureException(new Error('Error page displayed'), {
      extra: {
        error: this.error,
        route: this.$route,
      },
    })
  },
}
</script>
