<template>
  <div class="body">
    <AprilHeader :hidden-header="hiddenHeader" :hide-stepper="hideStepper" />
    <div class="main">
      <slot />
    </div>
    <AprilFooter />
  </div>
</template>

<script lang="ts">
export default defineNuxtComponent({
  name: 'AprilBody',
  props: {
    hiddenHeader: {
      type: Boolean,
      default: false,
    },
    hideStepper: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props) => {
    const i18n = useI18n()
    useHead({
      // inject HTML attributes on <body />
      // add class for theme blue
      bodyAttrs: {
        class: 'theme-blue',
      },
    })
    return {
      i18n,
    }
  },
})
</script>

<style lang="scss">
.body {
  display: flex;
  flex-direction: column;
  height: 100svh;

  @media #{$mq-medium} {
    height: initial;
  }

  .main {
    margin-top: april-rem(69);
    width: 100%;

    @media #{$mq-medium} {
      min-height: calc(100vh - april-rem(350));
      margin-top: april-rem(84);
    }

    & > .ui-wrapper {
      margin-bottom: april-rem(2 * $spacing-2xl);
      min-height: calc(100svh - 167px - april-rem(2 * $spacing-2xl));

      @media #{$mq-medium} {
        min-height: inherit;
      }
    }
  }

  .site-footer {
    margin-top: 0;
  }
}
</style>
